import React from "react";
import { Col, Row } from "../GridSystem";
import {
  StyledGridImageLink,
  StyledGridImageItemInner,
  StyledGridImg,
  StyledFullWidthContainer,
} from "./style";

export default function GridImages({ children }) {
  return <Row>{children}</Row>;
}

interface IGridImageItemProps {
  title: string;
  slug: string;
  imageSrc: string;
}

export const GridImageItem = ({
  title,
  slug,
  imageSrc,
  ...otherProps
}: IGridImageItemProps) => {
  return (
    <Col sm={12} md={6} lg={4}>
      <StyledGridImageLink to={`/${slug}`} {...otherProps}>
        <StyledFullWidthContainer>
          <StyledGridImageItemInner>
            <h2>{title}</h2>
            <StyledGridImg
              src={imageSrc}
              width={800}
              height={600}
              alt={title}
            />
          </StyledGridImageItemInner>
        </StyledFullWidthContainer>
      </StyledGridImageLink>
    </Col>
  );
};
