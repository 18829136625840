import styled from 'styled-components'

export const StyledFilterContainer = styled.div`
  display: block;
  text-align: right;
  font-weight: 600;
  font-family: Poppins,sans-serif;
  label{
    margin-right: 1rem;
  }
  select{
    height: 32px;
  }
`;
