import React from "react";
import { StyledButton, StyledLinkButton } from "./style";

export default function Button(props) {
  const { children, icon, to, ...otherProps } = props;
  const innerContent = (
    <>
      {icon ? (
        <img alt="Button icon" src={icon} width={32} height={32} />
      ) : (
        <></>
      )}{" "}
      {children}
    </>
  );

  return to ? (
    <StyledLinkButton to={to} {...otherProps}>
      {innerContent}
    </StyledLinkButton>
  ) : (
    <StyledButton {...otherProps}>{innerContent}</StyledButton>
  );
}
