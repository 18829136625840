import { Link } from "gatsby";
import styled, {css} from "styled-components";
import { globalColors, mediaQueries } from "../globalStyles";

const cssFullWidth = css`
  @media ${mediaQueries.sm} {
    width: 100%;
    justify-content: center;
  }
`

const cssButton = css<{fullWidth?:boolean}>`
  background: ${globalColors.secondary};
  color: black;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border: 2px solid transparent;
  border-radius: 10px;
  user-select: none;
  &:hover {
    background: ${globalColors.btnHoverBg};
    border: 2px solid ${globalColors.highContrastText};
  }
  &:active {
    border: 2px solid ${globalColors.secondary};
  }
  img {
    margin-right: 1rem;
  }
  @media ${mediaQueries.sm} {
    font-size: 1.125rem;
  }
  @media ${mediaQueries.sm} {
    width: ${(props) => props.fullWidth ? '100%' : 'auto'};
    justify-content: ${(props) => props.fullWidth ? 'center' : 'normal'};;
  }

`

export const StyledButton = styled.button`
  ${cssButton}
`;


export const StyledLinkButton = styled(Link)`
  ${cssButton}
`
