import styled from "styled-components";
import { Link } from "gatsby";
import { globalColors, mediaQueries } from "../globalStyles";

export const StyledGridImageItemInner = styled.div`
  position: relative;
  h2 {
    opacity: 0;
    color: white;
    position: absolute;
    top: calc(50% - 2rem);
    left: 0px;
    padding: 1rem;
    text-align: center;
    width: 100%;
    margin-bottom: 0;
  }
  &:hover,
  &:focus {
    ::before {
      content: "";
      background-color: ${globalColors.gridImageItemHover};
      position: absolute;
      width: 100%;
      height: 100%;
    }
    h2 {
      opacity: 1;
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }
  @media ${mediaQueries.sm} {
    & {
      &:hover,
      &:focus {
        h2 {
          animation-name: none;
        }
        ::before {
          content: none;
        }
      }
    }
  }
`;

export const StyledGridImg = styled.img`
  width: 100%;
  height: auto;
  display: block;
  background: ${globalColors.imagePlaceholderBg};
`;

export const StyledFullWidthContainer = styled.div`
  @media ${mediaQueries.sm} {
    & {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
`;

export const StyledGridImageLink = styled(Link)`
  display: block;
  @media ${mediaQueries.sm} {
    & {
      h2 {
        opacity: 1;
        bottom: 0;
        top: initial;
        background-color: ${globalColors.gridImageItemHover};
      }
      &:hover,
      &:focus {
        ::before {
          content: none;
        }
      }
    }
  }
`;
