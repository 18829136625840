import React from "react";
import { StyledFilterContainer } from "./style";
import { localiseText } from "../../translations";

export const NO_FILTER = "NO_FILTER";

export default function PortifolioFilter({
  categories,
  filter,
  locale,
  onChange,
}) {
  return (
    <StyledFilterContainer>
      <label htmlFor="portifolioFilter">
        {localiseText(locale, "filter_by")}
      </label>
      <select
        name="portifolioFilter"
        id="portifolioFilter"
        value={filter}
        onChange={onChange}
      >
        <option value={NO_FILTER}>{localiseText(locale, "show_all")}</option>
        {categories.map(({ node }) => {
          return (
            <option key={node.id} value={node.id}>
              {node.category}
            </option>
          );
        })}
      </select>
    </StyledFilterContainer>
  );
}
