import React, { useState } from "react";
import { useEffect } from "react";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import GridImages, { GridImageItem } from "../components/GridImages";
import PortifolioFilter, { NO_FILTER } from "../components/PortifolioFilter";
import Button from "../components/Button";
import InsertMetaTags from "../components/InsertMetaTags";
import { localiseText } from "../translations";

const PORTIFOLIO_FILTER_KEY = "PORTIFOLIO_FILTER_KEY";
const _window = typeof window !== "undefined" ? window : {};

const PortifolioPage = ({data}) => {
  const postCategories = data.categories.edges;
  const posts = data.posts.edges;
  const { siteMetadata: { locale } } = data.siteData;

  const [filter, setFilter] = useState(
    _window.sessionStorage?.getItem(PORTIFOLIO_FILTER_KEY) || NO_FILTER
  );
  const [filteredPosts, setFilteredPosts] = useState(posts);

  useEffect(() => {
    setFilteredPosts(
      posts.filter(
        ({ node }) => filter === NO_FILTER || node.category.id === filter
      )
    );
  }, [filter, posts]);

  const handleChangeFilter = (e) => {
    setFilter(e.target.value);
    _window.sessionStorage?.setItem(PORTIFOLIO_FILTER_KEY, e.target.value);
    trackCustomEvent({
      category: "Selected portifolio filter",
      action: "Select",
      label: postCategories.find(({ node }) => node.id === e.target.value)?.node
        ?.category,
    });
  };

  const handleCleanFilter = (e) => {
    setFilter(NO_FILTER);
    _window.sessionStorage?.setItem(PORTIFOLIO_FILTER_KEY, NO_FILTER);
    trackCustomEvent({
      category: "Cleaned portifolio filter",
      action: "Click",
    });
  };

  return (
    <Layout pageTitle="Portifolio" locale={locale}>
      <InsertMetaTags title="Priscila Tambalo Design - Portifolio" />
      <PortifolioFilter
        categories={postCategories}
        filter={filter}
        onChange={handleChangeFilter}
        locale={locale}
      />
      <GridImages>
        {filteredPosts.map(({ node }, index) => {
          const imageSrc = `${node.mainImage.file.url}?w=800&h=600&fit=thumb&fm=webp&q=50`;
          return (
            <GridImageItem
              id={`post_${index}`}
              title={node.title}
              slug={node.slug}
              imageSrc={imageSrc}
              key={node.id}
            />
          );
        })}
      </GridImages>
      {filteredPosts.length === 0 ? (
        <div style={{ textAlign: "center", marginTop: "3rem" }}>
          <p>{localiseText(locale, 'no_records_found')}</p>
          <Button alt={localiseText(locale, 'clean_filter_btn')} onClick={handleCleanFilter}>
            {localiseText(locale, 'no_records_found')}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export const query = graphql`
query($locale: String) {
    posts: allContentfulPost(filter: { node_locale: { eq: $locale} } sort: { fields: destaque, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          mainImage {
            id
            title
            file {
              url
            }
          }
          category {
            id
            category
          }
        }
      }
    }
    categories: allContentfulPostCategory(
      sort: { fields: order }
      filter: {node_locale: { eq: $locale} post: { elemMatch: { content: { raw: { ne: null } } } } }
    ) {
      edges {
        node {
          id
          category
          post {
            id
          }
        }
      }
    }
    siteData: site { siteMetadata { locale } }
  }
`;

export default PortifolioPage;
